<template>
  <WStatsWidget
    :cols="cols"
    :subtitle="displayedSubtitle"
    :title="title"
    :loading="!isLoaded"
    :empty="isEmpty"
    class="review-number-by-period-widget"
    contentPadding="0.75em 0.75em 0 0.75em"
    contentWidth="100%"
  >
    <template #title>
      <slot name="title">
        {{ title || $t('voter_count_by_source') }}
      </slot>
    </template>

    <template #options>
      <div class="d-flex align-center">
        <div>
          <slot name="options">
          </slot>
        </div>
        <div>
          <w-drop-down-menu
            data-html2canvas-ignore
            :items="exportOptions"
            icon="mdi-download">
          </w-drop-down-menu>
        </div>
      </div>
    </template>

    <template #content >
      <div ref="chartReviewNumber">
        <Chart :options="chartOptionsProp" ref="chart" />
      </div>
    </template>

    <template #footer-left />
    <template #footer-right />
  </WStatsWidget>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { Chart } from 'highcharts-vue'
  import chartToPng from "@shared/helpers/chart-to-png"
  import Pdf from '@shared/helpers/exportToPdf/pdf'
  import dayjs from 'dayjs'
  import WidgetMixin from '@statistics/shared/widgets/widget_mixin'

  const COLORSBYSOURCE = {
    'avisVerifies': '#6DBBB6', // Neptune
    'bazaarVoice': '#8BBB99', // Bay Leaf
    'deliveroo': '#9FBAB8', // Shadow Green
    'facebook': '#2E1C4D', // Danube
    'yotpo': '#C86868', // Chetnut Rose
    'mailing': '#FFB800', // 
    'mono': '#E6DF8D', // Flax
    'normal': '#4EBDB7', // Antique Brass
    'pagesJaunes': '#FFD333', // Bright Sun 500
    'pollster': '#CEF2F0', // Sea Green 300
    'pull_web': '#9A8C5A', // Barley
    'push_web': '#9AA25F', // Green Smoke
    'qrcode': '#D3AB60', // Harvest Gold
    'skeepers': '#B27B59', // Wisteria
    'sms': '#DB3572', // Indigo
    'smsPull': '#66ACDF', // Jordy Blue
    'tripAdvisor': '#AE6B84', // Bouquet
    'ubereats': '#A6BB50', // Celery 500
    'url': '#95AD86', // Grey Chateau
    'web': '#88C788', // Eastern Blue
    'google': '#BA0022', // 
  }

  const SOURCEMAPPING = {
    'pull_web': 'web',
    'push_web': 'web',
    'sms_pull': 'sms',
    'null': 'normal'
  }

  export default {
    name: "ReviewNumberByPeriodWidget",
    components: {
      Chart
    },
    props: {
      title: { type: String, required: false },
      basedRequest: { type: Object },
      cols: { required: false, default: 12 }
    },
    data(){
      return {
        loaded: false,
        nbData: 0,
        chartOptionsProp: null
      }
    },
    mixins: [
      WidgetMixin
    ],
    computed: {
      ...mapGetters([
        'datesScope',
        'currentDashboard',
        'dashboardFilterUnit',
        'dashboardFilterBase64'
      ]),
      exportOptions() {
        return [
          { title: 'PNG', onClick: this.exportToPng },
          { title: 'PDF', onClick: this.exportToPdf }
        ]
      },
      isEmpty() {
        return this.nbData == 0
      },
      isLoaded() {
        return this.loaded
      },
      groupedBasedRequest() {
        return this.basedRequest.group([
          this.basedGroup,
          'source'
        ])
      },
      basedGroup() {
        if (this.dashboardFilterUnit === 'day') {
          return { created_at: { mod: 'DATE_TRUNC_DAY', cast: 'date' } }
        } else if (this.dashboardFilterUnit === 'week') {
          return { created_at: { mod: 'DATE_TRUNC_WEEK', cast: 'date' } }
        } else if (this.dashboardFilterUnit === 'month') {
          return { created_at: { mod: 'DATE_TRUNC_MONTH', cast: 'date' } }
        } else if (this.dashboardFilterUnit === 'quarter') {
          return { created_at: { mod: 'DATE_TRUNC_QUARTER', cast: 'date' } }
        } else if (this.dashboardFilterUnit === 'year') {
          return { created_at: { mod: 'DATE_TRUNC_YEAR', cast: 'date' } }
        }
      },
      tickLimit() {
        switch (this.dashboardFilterUnit) {
          case 'day':
            return 6
          case 'week':
            return 6
          case 'month':
            return 12
          case 'quarter':
            return 6
          case 'year':
            return 6
        }
      },
    },
    asyncComputed: {
      chartOptions: {
        async get() {
          this.loaded = false
          this.nbData = 0

          let series = []
          let labels = []
          let colors = []
          let dateBegin = this.datesScope.dateBegin
          let dateEnd = this.datesScope.dateEnd
          let tickUnit = this.dashboardFilterUnit
          dateBegin = dayjs(dateBegin).subtract(this.tickLimit, tickUnit).format("YYYY-MM-DD")
          
          const response = await this.groupedBasedRequest.dateBetween(dateBegin, dateEnd).resolve("TonNomb",
            {
              tickNumber: 1,
              tickUnit: tickUnit,
              timeSerieParams: {
                limit: this.tickLimit,
                date_begin: dateBegin,
                date_end: dateEnd
              }
            },
            "time_series")

          for (const [key, value] of Object.entries(response.data.labels)) {
            for (const [date, date_readable] of Object.entries(value)) {
              labels.push(date_readable)
            }
          }
          let dataByGroupSource = {}
          for (const [key, value] of Object.entries(response.data.series)) {
            let datas = []
            
            for (const [idx, count] of Object.entries(value.data)) {
              if (count) {
                datas.push(count.countDistinctId)
                this.nbData += 1
              }
              else {
                datas.push(0)
              }
            }
            let sourceMapped = value.name in SOURCEMAPPING ? SOURCEMAPPING[value.name] : value.name
            if (sourceMapped in dataByGroupSource) {
              let idx = 0
              for (const val of datas) {
                dataByGroupSource[sourceMapped][idx] += val
                idx++
              }
            } else {
              colors.push(COLORSBYSOURCE[sourceMapped])
              dataByGroupSource[sourceMapped] = datas
            }
            
          }

          for (const [name, data] of Object.entries(dataByGroupSource)) {
            series.push({
              data: data,
              name: this.$t(`source.${name}`)
            })
          }

          let chartOptionsProp = {
            chart: {
              type: 'column',
            },
            xAxis: {
              categories: labels
            },
            plotOptions: {
              series: {
                thickness: 2,
                borderRadius: '20%',
                borderWidth: 0,
                borderColor: 'white',
                dataLabels: {
                    enabled: false
                },
                size: '100%',
                innerSize: '100%',
                stacking: 'normal'
              },
              column: {
            	  groupPadding: 0.05
              },
            },
            exporting: {
              enabled: false
            },
            colors: colors,
            series: series,
            title: {
              text: '',
              align: 'center',
              verticalAlign: 'middle'
            },
            credits: { 
              enabled: false
            }
          }
          
          this.chartOptionsProp = chartOptionsProp
          this.loaded = true
          return true
        },
        default: false,
      },
    },
    methods: {
      formatDate(date, format='YYYY-MM-DD') {
        return dayjs(date).locale(this.$i18n.locale).format(format)
      },
      exportFileName() {
        const pageName = this.$t(`voter_count_by_source`);
        const campaignName = this.currentDashboard.name;
        const period = this.dashboardFilterDates.text;
        const date = this.$date().format('ddd DD MMM HH_mm');

        return `${pageName} - ${campaignName} - ${period} - ${date}`;
      },
      exportToPng() {
        this.$store.dispatch("notifyInfo");
        const chart = this.$refs.chart.chart;
        const fileName = this.exportFileName()
        const title = this.title

        chartToPng(chart, { width: 700, fileName, title });
      },
      async exportToPdf() {
        this.$store.dispatch("notifyInfo");
        const chart = this.$refs.chart.chart;
        const fileName = this.exportFileName()
        const title = this.title

        const pdf = new Pdf({
          defaultBodyMargin: { left: 40, top: 40 }
        });

        await pdf.addPage()

        await pdf.addRow({}, async (row) => {
          await row.addCol({ width: '12' }, async (col) => {
            await col.addText(title, { color: "#212121", font: { style: 'semi-bold' } });
          });
        })
        
        await pdf.addRow({ marginTop: 20 }, async (row) => {
          await row.addCol({ width: '12' }, async (col) => {
            await col.addChart(chart, { width: 700 });
          });
        });

        pdf.save(fileName);
      },
    }
  }
</script>
