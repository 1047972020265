<template>
  <v-form
    :disabled="disabled"
    ref="form"
    v-model="isValid"
    class="send-message d-flex flex-column"
  >
    <p v-if="disabled" class="c-error">{{ $t('gmb_place_not_connected_error') }}</p>
    <div
      class="flex-grow-0 mb-5"
      :class="$vuetify.breakpoint.mdAndUp ? 'd-flex' : ''"
    >
      <PreFilledMsgSelector
        ref="prefilledMessageSelector"
        :voter="voter"
        :smartAnswerLoading="fetchingSmartAnswer"
        :prospect-fields="prospectFields"
        :g-brand-name="gBrandName"
        :fields-customization="fieldsCustomization"
        :user-message-count="conversationUserMessageCount"
        @select="preFillContent"
        @opened="prefilledMessageSelectorOpened"
        @smart-answer-requested="fetchSmartAnswer"
        :disabled="disabled"
      />
      <div class="flex-grow-1" />
      <div
        v-if="currentVoterSmartAnswersEnabled && (fetchingSmartAnswer || smartAnswerGenerated)"
        class="smart-answer c-violette bg-violette-light d-flex align-center justify-center px-3 py-2 ga-1 fb-13"
        :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : ''"
      >
        <WIcon
          icon="auto-fix-gradient"
          :icon-style="{fontSize: '18px'}"
        />
        <span v-if="fetchingSmartAnswer">{{ $t('feedbacks_detail_being_generated_by_ai') }}</span>
        <span v-else>{{ $t('feedbacks_detail_generated_by_ai') }}</span>
      </div>
    </div>

    <v-row class="flex-grow-1">
      <v-col class="py-0 my-1">
        <v-textarea
          v-model="formData.content"
          class="content-textarea f-14"
          :label="textareaHint || $t('sendMessage.message')"
          background-color="var(--oslo-gray-100)"
          :rules="[$required, noDuplicate, noInterpolationMissing]"
          :hide-details="false"
          name="content"
          height="100%"
          rows="0"
          dense
          filled
          no-resize
        />
      </v-col>
    </v-row>

    <v-row class="flex-grow-0">
      <v-col
        cols="4"
        class="text-left py-0 my-1 pr-1"
        v-if="displaySelect">
        <v-select
          attach
          background-color="var(--oslo-gray-100)"
          :style="`$vuetify.breakpoint.smAndUp ? 'max-width: 70%;' : ''}`"
          v-model="trustvilleDisplay"
          tem-text="text"
          item-value="value"
          :items="trustvilleDisplaySelectItems"
          :label="$t('trustville_display')"
          :hide-details="true"
          outlined
          dense
          >
          <template v-slot:selection="data">
            <v-icon v-if="$vuetify.breakpoint.smAndUp" small class="mr-1"> {{ data.item.icon }} </v-icon> {{ data.item.text }}
          </template>

          <template v-slot:item="{ item }">
            <v-icon v-if="$vuetify.breakpoint.smAndUp" small class="mr-1"> {{ item.icon }} </v-icon> {{ item.text }}
         </template>
        </v-select>
      </v-col>
      <v-col
        cols="2"
        class="text-left px-1 pl-0"
        v-if="displaySelect && $vuetify.breakpoint.smAndUp"
      >
        <v-tooltip transition="none" top>
          <template #activator="{ on }">
            <span v-on="on" color="primary">
              <v-icon small> mdi-help-circle-outline </v-icon>
            </span>
          </template>
          <span color="black" v-html="$t('trustville_display_select_tooltip')"></span>
        </v-tooltip>
      </v-col>

      <v-col class="text-right py-0 my-1" :cols="`sTrustvilleEnabled && $vuetify.breakpoint.smAndUp ? '6' : ''}`">
        <v-btn
          v-if="currentVoterEditingMessage"
          outlined
          text
          class="cancel-edit mr-2"
          @click="cancelEdit"
          :ripple="false"
          color="bodygrey"
        >
          {{ $t('cancel') }}
        </v-btn>

        <BtnSendMessageRedirectModal
          v-if="shouldDisplaySendMessageRedirectModal"
          :smartChannel="voterMixin_customerPriorityChannel"
          :isValid="isValid"
          :content="formData.content"
          :btnText="btnText"
          :voter="voter"
        />
        <v-btn
          v-else
          class="send"
          @click="sendMessage"
          :disabled="!isValid || disabled"
          :ripple="false"
          :loading="sendingMessage"
          color="secondary"
        >
          <w-icon
            v-if="['pages_jaunes', 'trip_advisor'].includes(voterMixin_customerPriorityChannel)"
            class="mr-1"
            :class="isValid ? 'c-white' : 'c-lightgrey'"
            :icon="voterMixin_customerPriorityChannel"
            :icon-style="{ fontSize: '18px' }"
          />
          <v-icon v-else left dark>{{ channelIcon }}</v-icon>
          {{ btnText }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import PreFilledMsgSelector from './PreFilledMsgSelector.vue'
import BtnSendMessageRedirectModal from '../../shared/BtnSendMessageRedirectModal.vue'
import VoterMixin from '../../../voter_mixin'

export default {
  name: 'SendMessage',
  components: {
    PreFilledMsgSelector,
    BtnSendMessageRedirectModal,
  },
  mixins: [
    VoterMixin,
  ],
  props: {
    gBrandName: { required: true },
    fieldsCustomization: { required: true },
    prospectFields: { required: true },
    smartAnswer: { type: String },
  },
  data() {
    return {
      isValid: false,
      sendingMessage: false,
      trustvilleDisplay: false,
      noMessageSend: true,
      formData: { content: '' },
      trustvilleDisplaySelectItems: [
        { icon: 'mdi-earth', value: true, text: this.$t('message_trustville_public') },
        { icon: 'mdi-lock',value: false, text: this.$t('message_trustville_private') }
      ],
      redirectionDialog: false,
      redirectLoading: 0,
      fetchingSmartAnswer: false,
      smartAnswerGenerated: false,
    }
  },
  asyncComputed: {
    conversationUserMessageCount: {
      async get() {
        const request = this.$basedRequest()
                            .select({ conversations: ['user_message_count'] })
                            .where({ voter_id: this.voter.id })

        const response = (await this.$resolve(request)).first()
        return response?.userMessageCount
      }
    },
    isTrustvilleEnabled: {
      default: false,
      async get() {
        const request = this.$basedRequest()
                            .select({ place_campaigns: ['is_trustville_enabled'] })
                            .where({ id: [this.voter.placeCampaignId] })

        const response = (await this.$resolve(request)).first();
        return response?.isTrustvilleEnabled;
      },
    },
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'currentUserIsAdmin',
      'currentUserLocale',
      'currentUserNeedValidationForCampaign',
      'currentVoterEditingMessage',
      'currentVoterShowedActions',
      'currentVoterSmartAnswersEnabled',
      'mainCampaign',
      'voterHasNoAnswer',
      'voterTrustvilleReportable',
    ]),
    disabled() {
      return (this.voter.source === 'google' && !this.voter.googleBrandPlace)
    },
    shouldDisplaySendMessageRedirectModal() {
      return this.isIndirectChannel &&
        !(this.needValidation || this.currentVoterEditingMessage)
    },
    isIndirectChannel() {
      return ['pages_jaunes', 'trip_advisor'].includes(this.voterMixin_customerPriorityChannel)
    },
    noUserMessageSent() {
      return this.conversationUserMessageCount === 0
    },
    displaySelect() {
      return (this.mainCampaign.trustvilleProductPreferenceEnabled || this.isTrustvilleEnabled)
          && this.voterTrustvilleReportable
          && this.noUserMessageSent
    },
    messageToValidate() {
      return this.voter.conversation.messages.find(message =>
        message.isNeedToValidate
      )
    },
    lastMessageToValidate() {
      return this.voter.conversation.messages.filter(message =>
        message.isNeedToValidate
      ).pop()
    },
    voterThirdPartyTransfer() {
      return this.messageToValidate?.voterThirdPartyTransfer
    },
    googleMessageToUpdate() {
      return this.voter.conversation.messages.find(message =>
        message.source === 'google' &&
        message.sender === 'user'
      )
    },
    textareaHint() {
      if (
        this.messageToValidate &&
        this.messageToValidate.content === this.formData.content
      ) {
        return this.$t(
          'message_submited_for_approval_by',
          { email: this.voterThirdPartyTransfer?.email || this.messageToValidate?.userEmail }
        )
      } else if (
        this.googleMessageToUpdate &&
        this.googleMessageToUpdate.content === this.formData.content
      ) {
        return this.$t('google_message_to_update')
      } else {
        return null
      }
    },
    needValidation() {
      return this.currentUserNeedValidationForCampaign(this.voter.campaignId)
    },
    btnText() {
      if (this.isEditingMessageForIndirectChannel) {
        return this.$t('update_for_validation')
      } else if (this.currentVoterEditingMessage && !this.needValidation) {
        return this.$t('update_and_send')
      } else if (this.messageToValidate && !this.needValidation) {
        return this.$t('validate_and_send')
      } else if (this.needValidation) {
        return this.currentVoterEditingMessage ? this.$t('update_for_validation') : this.$t('submit_for_validation')
      } else if (this.isIndirectChannel) {
        return this.$t('answer_on', { channel: this.$t(this.voterMixin_customerPriorityChannel) })
      } else {
        return this.$t('send_via', { channel: this.voterMixin_customerPriorityChannel })
      }
    },
    isEditingMessageForIndirectChannel() {
      return this.isIndirectChannel && this.currentVoterEditingMessage
    },
    noDuplicate() {
      return (
        !this.voter.conversation.messages
          .filter((m) => m.id !== this.currentVoterEditingMessage?.id)
          .map((m) => m.content)
          .includes(this.formData.content) || this.$t('alreadySentMessage')
      )
    },
    noInterpolationMissing() {
      let missingInterpolations = this.formData.content?.match(/\*\*.+\*\*/g)
      return (
        !missingInterpolations ||
        this.$t('missingDynamicFieldsInterpolations', {
          interpolations: missingInterpolations.join(', '),
        })
      )
    },
    channelIcon() {
      return {
        email: 'mdi-email-outline',
        sms: 'mdi-message-processing',
        google: 'mdi-google',
        facebook: 'mdi-facebook'
      }[this.voterMixin_customerPriorityChannel]
    }
  },
  methods: {
    async fetchSmartAnswer() {
      if (this.fetchingSmartAnswer) {
        return
      }
      this.fetchingSmartAnswer = true
      this.formData.content = ' '

      try {
        const response = await this.$api
                                   .wizville
                                   .smartAnswers
                                   .getSuggestion(
                                     this.voter.id,
                                     this.currentUserLocale
                                   )
        this.formData.content = response.suggestion
        // We don't `await` here because we want to display the answer as fast as possible
        // in the UI.
        this.$api
            .wizville
            .voters
            .updateVoterSmartAnswer(
              this.voter.uniqueId,
              { smartAnswer: response.suggestion }
            )
      } catch {
        this.$store.dispatch('notifyError', { message: this.$t('feedbacks_detail_failed_openai_call') })
      }

      this.smartAnswerGenerated = true
      this.fetchingSmartAnswer = false
    },
    clearVoterActions() {
      this.$store.dispatch('setShowVoterActions', false)
      this.$store.dispatch('setActionsTab', 'cleared')
    },
    cancelEdit() {
      this.clearVoterActions()
      this.$store.dispatch('setEditingMessage', null)
      if (this.messageToValidate) {
        this.$store.dispatch(
          'notifySuccess',
          {
            message: this.$t('flash_message_successfully_cancel_message_edit_for_validation'),
            timeout: 5000
          }
        )
      } else if (this.googleMessageToUpdate) {
        this.$store.dispatch(
          'notifySuccess',
          {
            message: this.$t('flash_message_successfully_cancel_message_edit'),
            timeout: 5000
          }
        )
      }
    },
    prefilledMessageSelectorOpened(value) {
      this.$store.dispatch('setIsActionSubMenuOpened', value)
    },
    preFillContent(content) {
      this.formData.content = content
    },
    async sendMessage() {
      if (this.isValid) {
        this.sendingMessage = true
        this.noMessageSend = false

        try {
          const response = await this.$api.wizville.messages.create(
            this.voter.uniqueId,
            {
              content: this.formData.content,
              channel: this.voterMixin_customerPriorityChannel,
              is_need_to_validate: this.isEditingMessageForIndirectChannel ? true : this.needValidation,
              trustville_display: this.trustvilleDisplay
            }
          )

          if (response.status === 'ok') {
            // The smart answer is deprecated once an answer has been sent.
            this.$api
                .wizville
                .voters
                .removeVoterSmartAnswer(this.voter.uniqueId)

            if (this.currentVoterEditingMessage && this.needValidation) {
              this.$store.dispatch('notifySuccess', { message: this.$t('flash_message_successfully_updated_message_for_validation'), timeout: 5000 })
            } else {
              this.$store.dispatch('notifySuccess', {
                message: response.message,
                timeout: 5000,
              })
            }

            if (this.currentVoterEditingMessage) {
              this.$store.dispatch('events',
                {
                  voterUpdate: {
                    voter: {
                      uniqueId: this.voter.uniqueId,
                      timelineELementUniqueId: this.currentVoterEditingMessage.id
                    }
                  }
                }
              )

              this.$store.dispatch('setEditingMessage', null)
            } else {
              this.$store.dispatch('events', {
                voterUpdate: { voter: { uniqueId: this.voter.uniqueId } },
              })
            }

            if (response.insatisfactionProcessed) {
              this.$store.dispatch(
                'voterInsatisfactionStatus',
                response.insatisfactionProcessed
              )
            }

          } else {
            this.notifyError(response.message)
          }
        } catch (error) {
          this.notifyError(error.message)
        }

        this.sendingMessage = false
      }
    }
  },
  async mounted() {
    if (this.lastMessageToValidate) {
      this.trustvilleDisplay = this.lastMessageToValidate?.trustvilleDisplay || false
    }

    this.$nextTick(function () {
      const message =
        this.messageToValidate ||
        this.googleMessageToUpdate

      if (message) {
        this.$store.dispatch('setEditingMessage',
          {
            id: message.id,
            content: message.content
          }
        )
      }

      if (this.currentVoterEditingMessage) {
        this.formData.content = this.currentVoterEditingMessage.content
      } else if (this.smartAnswer) {
        this.formData.content = this.smartAnswer
      }
    })
  },
  watch: {
    currentVoterShowedActions(value) {
      if (!value) {
        this.$refs.prefilledMessageSelector.close()
      }
    },
    currentVoterEditingMessage() {
      if (this.currentVoterEditingMessage) {
        this.formData.content = this.currentVoterEditingMessage.content
      }
    },
    smartAnswer(value) {
      if (value && this.formData.content == '') {
        this.formData.content = value
        this.smartAnswerGenerated = true
      }
    },
  }
}
</script>

<style lang="stylus">
  @import "~@theme/medias.styl"

  .v-input
    font-size: 13px

  .smart-answer
    border-radius: 4px

  .send-message
    height: 100%
    padding: 20px 10px 20px 10px

    .content-textarea
      height: 100%

      textarea
        line-height: 18px

    .content-textarea .v-input__control
      height: 100%
      flex-wrap: unset

    +forBreakpoints(mobile, tablet)
      .content-textarea
        min-height: 200px
</style>
