<template>
  <div class="ma-3">
    <v-skeleton-loader v-if="defaultPrompt === undefined" type="paragraph" />
    <div v-else>
      <v-card outlined class="pa-3">
        <div class="d-flex">
          <div class="d-flex flex-column">
            <div class="fb-18 c-primary">
              {{ $t('smart_answers_preferences_title') }}
            </div>
            <div class="ga-1 f-14 c-grey">
              {{ $t('smart_answers_preferences_subtitle') }}
            </div>
          </div>
          <div class="flex-grow-1" />
          <v-btn-toggle
            v-if="campaignSmartAnswersPreference !== undefined"
            color="info"
            flat
            mandatory
            outlined
            v-model="enabled"
          >
            <v-btn @click="() => save({ enabled: false })" class="v-btn v-size--default"><span>OFF</span></v-btn>
            <v-btn @click="() => save({ enabled: true })" class="v-btn v-size--default"><span>ON</span></v-btn>
          </v-btn-toggle>
        </div>
      </v-card>
      <v-card class="mt-3 pa-3" outlined>
        <v-row class="fb-16 c-primary">
          <v-col>
            {{ $t('smart_answers_preferences_settings_title') }}
          </v-col>
        </v-row>
        <v-row class="mt-3">
          <v-col cols="12" md="6">
            <div>
              <v-textarea
                :label="$t('smart_answers_preferences_settings_prompt_title')"
                :rows="10"
                outlined
                v-model="prompt"
              />
              <v-btn @click="() => save({ prompt })" outlined>
                {{ $t('smart_answers_preferences_settings_save_button') }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-card flat class="bg-bodygrey" style="height: 250px; overflow: auto">
              <v-card-text>
                <table style="border-spacing: 1em 0.1em">
                  <tr>
                    <td class="fb-12">{review_author}</td>
                    <td class="f-12">{{ $t('smart_answers_preferences_settings_lexicon_review_author') }}</td>
                  </tr>
                  <tr>
                    <td class="fb-12">{review_date}</td>
                    <td class="f-12">{{ $t('smart_answers_preferences_settings_lexicon_review_date') }}</td>
                  </tr>
                  <tr>
                    <td class="fb-12">{review_score}</td>
                    <td class="f-12">{{ $t('smart_answers_preferences_settings_lexicon_review_score') }}</td>
                  </tr>
                  <tr>
                    <td class="fb-12">{review_comment}</td>
                    <td class="f-12">{{ $t('smart_answers_preferences_settings_lexicon_review_comment') }}</td>
                  </tr>
                  <tr>
                    <td class="fb-12">{brand_name}</td>
                    <td class="f-12">{{ $t('smart_answers_preferences_settings_lexicon_brand_name') }}</td>
                  </tr>
                  <tr>
                    <td class="fb-12">{place_name}</td>
                    <td class="f-12">{{ $t('smart_answers_preferences_settings_lexicon_place_name') }}</td>
                  </tr>
                  <tr>
                    <td class="fb-12">{place_locale}</td>
                    <td class="f-12">{{ $t('smart_answers_preferences_settings_lexicon_place_locale') }}</td>
                  </tr>
                  <tr>
                    <td class="fb-12">{user_locale}</td>
                    <td class="f-12">{{ $t('smart_answers_preferences_settings_lexicon_user_locale') }}</td>
                  </tr>
                  <tr style="height: 1em" />
                  <tr>
                    <td class="fb-12">{{ $t('smart_answers_preferences_settings_default_prompt_title') }}</td>
                    <td class="f-12">{{ defaultPrompt }}</td>
                  </tr>
                </table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mt-3 pa-3" outlined>
        <div class="fb-16 c-primary mb-3">
          {{ $t('smart_answers_preferences_test_title') }}
        </div>
        <v-row>
          <v-col
            v-for="review of lastCampaignReviews"
            :key="review.id"
            cols="12"
            md="6"
            lg="3"
          >
            <v-text-field
              :label="$t('smart_answers_preferences_tests_review_date')"
              :value="$helpers.dates.short(review.createdAt, true)"
              disabled
              outlined
            />
            <v-text-field
              :label="$t('smart_answers_preferences_tests_review_place')"
              :value="review.placeName"
              disabled
              outlined
            />
            <v-text-field
              :label="$t('smart_answers_preferences_tests_review_score')"
              outlined
              v-model="review.score"
            />
            <v-textarea
              :label="$t('smart_answers_preferences_tests_review_comment')"
              :rows="7"
              outlined
              v-model="review.mainVerbatimContent"
            />
            <v-textarea
              :label="$t('smart_answers_preferences_tests_review_used_prompt')"
              :rows="7"
              :value="review.usedPrompt"
              disabled
              outlined
            />
            <v-textarea
              :label="$t('smart_answers_preferences_tests_review_openai_suggestion')"
              :rows="7"
              :value="review.suggestion"
              disabled
              outlined
            />
          </v-col>
        </v-row>
        <v-btn @click="test" :loading="loadingTests" outlined>
          {{ $t('smart_answers_preferences_tests_test_button') }}
        </v-btn>
      </v-card>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Vue from 'vue'

  export default {
    name: "SmartAnswers",
    data() {
      return {
        enabled: false,
        loadingTests: false,
        prompt: undefined,
      }
    },
    watch: {
      defaultPrompt(prompt) {
        if (!this.prompt) {
          this.prompt = prompt
        }
      }
    },
    methods: {
      async save(data) {
        if ('enabled' in data) this.enabled = data.enabled
        if ('prompt' in data) this.prompt = data.prompt

        const response = await this.$api
                                   .wizville
                                   .campaignPreferences
                                   .editCampaignSmartAnswersPreferences(
                                     this.currentCampaignPreferencesCampaignId,
                                     {
                                       enabled: this.enabled == 1,
                                       prompt: this.prompt,
                                     }
                                   )

        if (response.status == 'ok') {
          this.$store.dispatch('notifySuccess', {
            message: this.$t('smart_answers_preferences_saved')
          })
        } else {
          this.$store.dispatch('notifyError', {
            message: this.$t('smart_answers_preferences_error_while_saving')
          })
        }
      },
      async test() {
        this.loadingTests = true
        try {
          await Promise.all(
            this.lastCampaignReviews.map(async (review, index) => {
              const response = await this.$api
                                         .wizville
                                         .smartAnswers
                                         .getSuggestion(
                                           review.id,
                                           this.currentUserLocale,
                                           {
                                             reviewScore: review.score,
                                             reviewComment: review.mainVerbatimContent
                                           }
                                         )
              Vue.set(this.lastCampaignReviews, index, { ...review, ...response })
            })
          )
        } catch (e) {
          alert('Request failed')
          console.error(e)
        }
        this.loadingTests = false
      },
    },
    computed: {
      ...mapGetters([
        'currentCampaignPreferencesCampaignId',
        'currentUserLocale',
        'dashboardAvgScale',
      ])
    },
    asyncComputed: {
      defaultPrompt: {
        default: undefined,
        async get() {
          const request = this.$basedRequest()
                              .select({ admin_constants: ['value'] })
                              .where({ key: 'smart_answers_default_prompt' })

          return (await this.$resolve(request)).first().value
        }
      },
      campaignSmartAnswersPreference: {
        default: undefined,
        async get() {
          const request = this.$basedRequest()
                              .select({
                                campaign_smart_answers_preferences: [
                                  'enabled',
                                  'prompt',
                                ],
                              })
                              .where({
                                campaign_id: this.currentCampaignPreferencesCampaignId
                              })

          const campaignSmartAnswersPreference = (await this.$resolve(request)).first()
          if (!campaignSmartAnswersPreference) {
            return null
          }

          this.enabled = campaignSmartAnswersPreference.enabled ? 1 : 0
          this.prompt = campaignSmartAnswersPreference.prompt

          return campaignSmartAnswersPreference
        }
      },
      lastCampaignReviews: {
        default: [],
        async get() {
          // Retrieve reviews.
          let request = this.$basedRequest()
                            .select({
                              voters: [
                                'id',
                                'created_at',
                                'main_verbatim_content',
                                'avg_score',
                                'nps',
                                'source',
                                'place_name',
                              ],
                            })
                            .where({
                              campaign_id: this.currentCampaignPreferencesCampaignId,
                              main_verbatim_content: { is_not_null: {} },
                              language: this.currentUserLocale || "en",
                            })
                            .order(['created_at', 'desc'])
                            .limit(4)
          let reviews = (await this.$resolve(request)).data.voters

          if (reviews.length < 4) {
            request = this.$basedRequest()
                          .select({
                            voters: [
                              'id',
                              'created_at',
                              'main_verbatim_content',
                              'avg_score',
                              'nps',
                              'source',
                              'place_name',
                            ],
                          })
                          .where({
                            campaign_id: this.currentCampaignPreferencesCampaignId,
                            main_verbatim_content: { is_not_null: {} },
                          })
                          .order(['created_at', 'desc'])
                          .limit(4)
            reviews = (await this.$resolve(request)).data.voters
          }

          // Define score scale.
          let scoreScale
          const isReviewFromPublicPlatform = [
                                               'google',
                                               'facebook',
                                               'pages_jaunes',
                                               'trip_advisor'
                                             ].includes(reviews[0].source)
          if (isReviewFromPublicPlatform) {
            scoreScale = 5
          } else {
            scoreScale = reviews[0].nps !== null ? 10 : this.dashboardAvgScale.max
          }
          // Define review scores.
          return reviews.map(voter => {
            if (voter.nps !== null) {
              voter.score = `${voter.nps}/10`
            } else {
              voter.score = `${voter.avgScore}/${scoreScale}`
            }
            return voter
          })
        }
      },
    }
  }
</script>

<style lang="stylus">
  .more
    color: var(--dark) !important

    &:hover,
    &:focus
      text-decoration: underline

  .v-text-field__slot
    textarea
      line-height: 18px
</style>
