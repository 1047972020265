import ApiCall from '@api/apiCall.js'

export async function export_campaign(campaign_id, params) {
  const response = await new ApiCall().post(`/api/wizville/campaigns/${campaign_id}/export`, {
    campaign: params
  });

  return response.data
}

export async function export_emailings(campaign_id, placeIds, dateBegin, dateEnd) {
  const response = await new ApiCall().post(`/api/wizville/campaigns/${campaign_id}/export_emailings`, { placeIds, dateBegin, dateEnd });

  return response.data
}

export async function export_trustville_state(campaign_id, placeIds, dateBegin, dateEnd) {
  const response = await new ApiCall().post(`/api/wizville/campaigns/${campaign_id}/export_trustville_state`, { placeIds, dateBegin, dateEnd });

  return response.data
}