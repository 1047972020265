<template>
  <span class="lh-16" v-if="voter">
    <span v-if="isPublishableAndEligible">
      <v-tooltip top :disabled="isOnline">
        <template #activator="{ on }">
          <v-hover v-slot="{ hover }">
            <span>
              <span v-on="on">
                <WIcon
                  icon="trustville_gradient"
                />
              </span>
              <span>
                <span class="fb-13 c-primary" v-if="hover && $vuetify.breakpoint.smAndUp">Trustville</span>
                <span class="f-11 c-purple" v-if="forceSubState || hover">{{ $t(`trustvilleBadgeMainStatus${displayedStatus}`) }}</span>
              </span>
            </span>
          </v-hover>
        </template>

        {{ displayedLabel }}
      </v-tooltip>
    </span>
    <span v-else>
      <v-tooltip top :disabled="!voterTrustvilleStateWithLabel">
        <template #activator="{ on }">
          <span v-on="on" class="mr-1">
            <WSourceIcon :source="voter.source" class="pl-1" />
          </span>
          <span>
            <span class="f-11 c-lightgrey" v-if="forceSubState && voterTrustvilleStateWithLabel">{{ $t(`trustvilleBadgeMainStatus${displayedStatus}`) }}</span>
          </span>
        </template>
        {{ displayedLabel }}
      </v-tooltip>
    </span>
  </span>
</template>

<script>
export default {
  name: 'TrustvilleBadge',
  props: ['voter', 'forceSubState'],
  computed: {
    isPublishableAndEligible() {
      return (
        ["publishable", "under_moderation"].includes(this.voterTrustvilleStateWithLabel?.state) && 
        this.voterTrustvilleStateWithLabel?.subStates.filter(
          subState => ["before_trustville_first_vote", "diffusion_period_expired"].includes(subState.name)
        ).length == 0
      )
    },
    isSoonOnline() {
      return this.voterTrustvilleStateWithLabel?.state === "publishable" &&
        this.voterTrustvilleStateWithLabel?.subStates.map(subState => subState.name).includes("soon_online")
    },
    isUnpublished() {
      return this.voterTrustvilleStateWithLabel?.state === "unpublishable"
    },
    isUnderModeration() {
      return this.voterTrustvilleStateWithLabel?.state === "under_moderation"
    },
    isOnline() {
      return this.isPublishableAndEligible && !this.isSoonOnline && !this.isUnpublished && !this.isUnderModeration
    },
    displayedStatus() {
      if (this.isSoonOnline) {
        return "soonOnline"
      } else if (this.isUnpublished) {
        return "unpublished"
      } else if (this.isUnderModeration) {
        return "underModeration"
      } else if (this.isOnline) {
        return "online"
      } else {
        return "Unknown status"
      }
    },
    displayedLabel() {
      return this.voterTrustvilleStateWithLabel?.subStates[0]?.label
    },
    voterTrustvilleStateWithLabel() {
      return this.voter?.trustvilleStateWithLabel
    }
  }
}
  
</script>
