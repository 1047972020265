import DashboardLayout from '@platform/layouts/DashboardLayout'
import { legacyOldUrl } from '@shared/helpers/env-helpers'
import store from '@platform/store'
import { getUserFilters } from '@api/wizville/users'

import Ranking from '@platform/pages/dashboard/Ranking'
import Statistics from '@platform/pages/dashboard/Statistics'
import Competitions from '@platform/pages/dashboard/Competitions'
import Feedbacks from '@platform/pages/dashboard/Feedbacks'
import Achievements from '@platform/pages/dashboard/Achievements'
import Products from '@platform/pages/dashboard/Products'
import CampaignExport from '@platform/pages/dashboard/CampaignExport'
import Reportings from '@platform/pages/dashboard/Reportings'
import Collaborators from '@platform/pages/dashboard/Collaborators'

async function dashboardLoader(to, from, next) {
  let dashboardFilters = null

  try {
    dashboardFilters =  JSON.parse(decodeURIComponent(escape(atob(to.query.dashboard_filters))))
  } catch(e) {
    dashboardFilters = (store.getters.dashboardFilters || (await getUserFilters(to.params.dashboardType, to.params.dashboardId)))
  }

  await store.dispatch("dashboardContext", {
    dashboardType: to.params.dashboardType,
    dashboardId: to.params.dashboardId,
    dashboardFilters: dashboardFilters
  })
}

async function voterLoader(to, from, next) {
  if (to.query.dashboard_filters) {
    if (to.params.voterUniqueId) {
      await store.dispatch("voterContext", to.params.voterUniqueId)
      if (store.getters.currentVoter?.campaignId) {
        await store.dispatch("surveyContext", store.getters.currentVoter.campaignId)
      }
    } else {
      await store.dispatch("destroyVoterContext")
    }
  }
}

async function loadDashboard(to, from, next) {
  await dashboardLoader(to, from, next)
  next()
}

async function loadDashboardAndVoter(to, from, next) {
  await dashboardLoader(to, from, next)
  await voterLoader(to, from, next)

  next()
}

export default {
  meta: {
    beforeEach: loadDashboard
  },

  path: '/dashboard/:dashboardType/:dashboardId',
  component: DashboardLayout,

  children: [
    {
      path: '/',
      name: 'Dashboard',
      beforeEnter: (to, from, next) => {
        let path = ''

        if (store.getters.hasAccessToNewPlatformStatisticTab) {
          path = '/statistics/summary/1'
        } else if (store.getters.hasAccessToNewPlatformRankingTab) {
          path = `/ranking/places/brand/${store.getters.defaultRanking}`
        } else if (store.getters.hasAccessToNewPlatformFeedbackTab) {
          path = '/feedbacks/all'
        } else if (store.getters.hasAccessToNewPlatformLocalInsightTab) {
          path = '/competitions/local/period'
        } else if (store.getters.hasAccessToNewPlatformAchievementsTab) {
          path = '/achievements'
        }

        if (path == '') {
          next()
        }
        next({ path: `/dashboard/${to.params.dashboardType}/${to.params.dashboardId}${path}` })
      }
    },
    {
      path: 'statistics/:sectionType(summary|zoom|nps|nis|social|satisfaction|questions|topics|verbatim|feedbackTreatment|ReviewNumbers)/:sectionId/:subSectionId?',
      name: 'Statistics',
      component: Statistics,
      meta: { nameTranslationKey: "dashboard_sidebar_statistics" },

      props: (route) => ({
        sectionType: route.params.sectionType,
        sectionId: route.params.sectionId,
        subSectionId: route.params.subSectionId
      })
    },
    {
      path: 'ranking/:rankBy(places|group|voter_info)/:rankById/:scope(mine|group|network)?',
      name: 'Ranking',
      component: Ranking,
      meta: { nameTranslationKey: "dashboard_sidebar_ranking" },
      props: (route) => ({
        rankBy: route.params.rankBy,
        rankById: route.params.rankById,
        scope: route.params.scope,
      })
    },
    {
      meta: {
        beforeEach: loadDashboardAndVoter,
        nameTranslationKey: "votedashboard_sidebar_voters"
      },
      path: 'feedbacks/:status/:subStatus/:voterUniqueId?/:section?',
      name: 'Feedbacks',
      component: Feedbacks,
      props: (route) => ({
        status: route.params.status,
        subStatus: route.params.subStatus,
        section: route.params.section
      })
    },
    {
      meta: {
        beforeEach: loadDashboard,
        nameTranslationKey: "dashboard_sidebar_competitors"
      },
      path: 'competitions/:competitorMode(local|national|country)/:periodType/:competitorScope?/:campaignSmartBenchmarkPreferenceId?',
      name: 'Competitions',
      component: Competitions,
      props: (route) => ({
        competitorMode: route.params.competitorMode,
        competitorScope: route.params.competitorScope || store.getters.defaultCompetitorScope,
        periodType: route.params.periodType,
        campaignSmartBenchmarkPreferenceId: route.params.campaignSmartBenchmarkPreferenceId || undefined
      }),
    },
    {
      meta: {
        beforeEach: loadDashboard,
        nameTranslationKey: "dashboard_sidebar_campaign_export"
      },
      path: 'campaign_export',
      name: 'CampaignExport',
      component: CampaignExport,
    },
    {
      path: 'achievements/:page(leaderboard|my_achievements)/:elementId?',
      name: 'Achievements',
      component: Achievements,
      props: (route) => ({
        page: route.params.page,
        elementId: route.params.elementId,
      }),
    },
    {
      path: 'collaborators',
      name: 'Collaborators',
      component: Collaborators
    },
    {
      path: 'reportings',
      name: 'Reportings',
      component: Reportings
    },
    {
      path: 'legacy',
      name: 'OldPlatform',
      beforeEnter(to, from, next) {
        window.open(`${legacyOldUrl}/dashboards/${to.params.dashboardId}/${to.params.dashboardType}/stats/dashboard?nup=1&alk=${store.getters.currentUser.alk}`)
      }
    },
    {
      meta: {
        beforeEach: loadDashboard
      },
      path: ':mode(product_categories|products)/:campaignId/:productCategoryId?',
      name: 'Products',
      component: Products,
      props: (route) => (
        {
          mode: route.params.mode,
          campaignId: route.params.campaignId,
          productCategoryId: (route.params.productCategoryId === 'all' ? null : route.params.productCategoryId)
        }
      ),
    }
  ]
}
