<template>
  <v-expansion-panels class="google-brand-accounts px-4 pt-4">
    <v-expansion-panel>
      <v-expansion-panel-header class="d-flex align-center px-4 ga-2">
        <h4 class="f-16 c-primary">
          <span>{{ $t('google_brand_accounts_title') }}</span>
          <span v-if="!loading">{{ `(${connectionCount})` }}</span>
        </h4>
        <div
          class="text-right"
          v-if="!loading"
        >
          <v-menu
            max-width="130px"
            offset-y
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="f-13"
                color="primary"
                outlined
                v-bind="attrs"
                v-on="on"
              >
                <div class="d-flex align-center ga-1">
                  <span>
                    {{ $t('google_brand_accounts_new_connection_title') }}
                  </span>
                  <v-icon class="f-14">mdi-chevron-down</v-icon>
                </div>
              </v-btn>

            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in newConnectionItems"
                :key="index"
                @click="item.onClick"
              >
                <v-list-item-title class="f-13 d-flex align-center ga-2">
                  <v-icon class="f-14" v-text="item.icon"/>
                  <span>{{ item.title }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            class="f-13"
            color="primary"
            outlined
            @click="refreshBrand"
          >
            {{ $t('google_brand_accounts_refresh_title') }}
          </v-btn>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          v-show="!loading"
          group-by="googleBrandId"
          :headers="headers"
          :items="googleBrandAccounts"
          mobile-breakpoint="0"
          class="v-datatable-without-y-borders"
          :sort-by="['googleBrandPlacesCount']"
          :sort-desc="[true]"
          fixed-header
          height="320px"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:group.header="{ group, items, toggle, isOpen }">
            <td
              :colspan="headers.length"
            >
              <div class="d-flex align-center py-2">
                <div
                  class="d-flex flex-column"
                >
                  <div class="d-flex align-center ga-1">
                    <span
                      class="fb-14"
                    >
                      {{ items[0].googleBrandEmail }}
                    </span>
                    <span
                      class="f-14"
                    >
                      {{ $tc('google_brand_accounts_count', items.length) }}
                    </span>
                  </div>
                </div>
                <div class="d-flex align-center ml-auto ga-1">
                  <v-btn
                    icon
                    @click="disconnectGoogleBrand(group)"
                  >
                    <v-icon
                      small
                    >
                      mdi-logout
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="ml-2"
                    @click="toggle"
                    small
                    icon
                    :ref="group"
                    :data-open="isOpen"
                  >
                    <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                    <v-icon v-else>mdi-chevron-down</v-icon>
                  </v-btn>
                </div>
              </div>
            </td>
          </template>
          <template v-slot:item.accountName="{ item }">
            <div class="d-flex align-center ga-1">
              <span
                class="f-12"
              >
                {{ item.accountName }}
              </span>
              <span
                class="f-12"
              >
                {{ $tc('google_brand_places_count', item.locationCount) }}
              </span>
            </div>
          </template>
          <template v-slot:item.hasNotificationsEnabled="{ item }">
            <div
              class="d-flex justify-center align-center ga-1"
              v-if="item.hasNotificationsEnabled"
            >
              <v-icon
                small
                class="c-carribean-green"
              >
                mdi-check-circle-outline
              </v-icon>
              <span class="text-no-wrap">{{item.lastNotificationAt}}</span>
            </div>
            <v-icon small class="c-red" v-else>mdi-close-circle-outline</v-icon>
          </div>
          </template>
        </v-data-table>
        <v-skeleton-loader v-show="loading" type="paragraph" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
  import dayjs from 'dayjs'
  import { mapGetters } from 'vuex'
  import _uniq from "lodash/uniq"

  export default {
    name: "GoogleBrandAccountsTable",
    data() {
      return {
        selectedGoogleBrandAccount: [],
        loading: true,
        refreshLoading: false,
        disconnectLoading: false
      }
    },
    computed: {
      ...mapGetters([
        'currentUser'
      ]),
      headers() {
        return [
          {
            text: this.$t('google_brand_account_name'),
            value: 'accountName',
            sortable: false
          },
          {
            text: this.$t('google_brand_account_id'),
            value: 'name',
            sortable: false,
            cellClass: 'f-12'
          },
          {
            text: this.$t('google_brand_account_has_notifications_enabled'),
            value: 'hasNotificationsEnabled',
            align: 'center',
            sortable: false,
            cellClass: 'f-12'
          }
        ]
      },
      newConnectionItems() {
        return [
          {
            title: this.$t('google_brand_accounts_new_connection_open'),
            onClick: this.openNewConnectionLink,
            icon: 'mdi-open-in-new'
          },
          {
            title: this.$t('google_brand_accounts_new_connection_copy'),
            onClick: this.copyNewConnectionLink,
            icon: 'mdi-content-copy'
          }
        ]
      },
      connectionCount(){
        return _uniq(this.googleBrandAccounts.map(account=> {
          return account.googleBrandId
        })).length
      }
    },
    asyncComputed: {
      authorizationUri: {
        async get() {
          let req = this.$basedRequest().select({
            brands: [
              {
                google_my_business_authorization_uri: {
                  params: {
                    redirect_after_url: location.href
                  }
                }
              }
            ]
          }).where({
            'id': this.currentUser.brandId,
          })

          return (await this.$resolve(req))?.first()?.googleMyBusinessAuthorizationUri
        }
      },
      googleBrandAccounts: {
        default: [],
        async get() {
          this.loading = true
          let req = this.$basedRequest().select({
            google_brand_accounts: [
              { 'MAX_account_name': { as: 'accountName' } },
              { 'MAX_id': { as: 'id' } },
              { 'COUNT_DISTINCT_lj_google_brand_places_id': { as: 'googleBrandPlacesCount' } },
              { 'COUNT_DISTINCT_lj_unasigned_google_brand_places_id': { as: 'unasignedGoogleBrandPlacesCount' } },
              { 'MAX_name': { as: 'name' } },
              { 'BOOL_AND_has_notifications_enabled': { as: 'hasNotificationsEnabled' } },
              { 'MAX_google_brands_id': { as: 'googleBrandId' } },
              { 'MAX_google_brands_email': { as: 'googleBrandEmail' } },
              { 'MAX_lj_google_brand_places_last_notification_at': { as: 'lastNotificationAt' } }
            ]
          }).where({
            'brands_id': this.currentUser.brandId,
          }).group(
            ['id']
          )
          // req = req.where({
          //   name_search: { 'search': this.search }
          // })
          const result = (await this.$resolve(req))?.data || []

          this.loading = false
          return Object.values(result).map(googleBrandAccount => {
            if (googleBrandAccount?.lastNotificationAt) {
              googleBrandAccount.lastNotificationAt = dayjs(
                googleBrandAccount?.lastNotificationAt
              ).format(
                'DD-MM-YYYY HH:mm:ss'
              )
            }
            googleBrandAccount.locationCount =
              (googleBrandAccount.googleBrandPlacesCount || 0) +
              (googleBrandAccount.unasignedGoogleBrandPlacesCount || 0)

            return googleBrandAccount
          })
        }
      }
    },
    methods: {
      openNewConnectionLink() {
        window.open(this.authorizationUri, '_blank', 'noreferrer');
      },
      copyNewConnectionLink() {
        const _this = this
        this.$copyText(this.authorizationUri).then(function (e) {
          _this.$store.dispatch('notifySuccess', {
            message: _this.$t(
              'flash_message_google_my_business_successfully_copy_link',
            ),
            timeout: 5000
          })
        }, function (e) {
          _this.$store.dispatch('notifyError', {
            message: _this.$t(
              'flash_message_google_my_business_fail_to_copy_link',
            ),
          })
        })
      },
      async disconnectGoogleBrand(google_brand_id) {
        if (confirm(this.$t('google_my_business_disconnect_google_brand_confirm_message'))) {
          try {
            this.disconnectLoading = true
            const response = await this.$api.wizville.googleMyBusiness.destroyGoogleBrand(google_brand_id)

            if (response.status === "ok") {
              this.$store.dispatch('notifySuccess', {
                message: this.$t(
                  'flash_message_google_my_business_successfully_disconnected_google_brand',
                ),
                timeout: 5000
              })
            } else {
              this.$store.dispatch('notifyError', { message: response.message })
            }
            this.disconnectLoading = false
          } catch (error) {
            this.disconnectLoading = false
            this.$store.dispatch('notifyError', { message: error.message })
          }
        }
      },
      async refreshBrand() {
        try {
          this.refreshLoading = true
          const response = await this.$api.wizville.googleMyBusiness.refreshBrand()

          if (response.status === "ok") {
            this.$store.dispatch('notifySuccess', {
              message: this.$t(
                'flash_message_google_my_business_successfully_refresh_brand',
              ),
              timeout: 5000
            })
          } else {
            this.$store.dispatch('notifyError', { message: response.message })
          }
          this.refreshLoading = false
        } catch (error) {
          this.refreshLoading = false
          this.$store.dispatch('notifyError', { message: error.message })
        }
      }
    }
  }
</script>
