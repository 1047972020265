<template>
  <div>
    <ReviewsExport />
    <EmailingsExport v-if="currentAdmin" />
    <TrustvilleStateExport v-if="currentAdmin" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ReviewsExport from "./ReviewsExport"
  import EmailingsExport from "./EmailingsExport"
  import TrustvilleStateExport from "./TrustvilleStateExport"

  export default {
    name: "CampaignExport",
    components: {
      ReviewsExport,
      EmailingsExport,
      TrustvilleStateExport
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters(['currentAdmin'])
    }
  }
</script>
