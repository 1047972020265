<template>
  <v-expansion-panels class="facebook-brands px-4 pt-4">
    <v-expansion-panel>
      <v-expansion-panel-header class="d-flex align-center px-4 ga-2">
        <h4 class="f-16 c-primary">
          <span>{{ $t('facebook_brands_title') }}</span>
          <span v-if="!loading">{{ `(${connectionCount})` }}</span>
        </h4>
        <div
          class="text-right"
          v-if="!loading"
        >
          <v-menu
            max-width="130px"
            offset-y
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="f-13"
                color="primary"
                outlined
                v-bind="attrs"
                v-on="on"
              >
                <div class="d-flex align-center ga-1">
                  <span>
                    {{ $t('facebook_brands_new_connection_title') }}
                  </span>
                  <v-icon class="f-14">mdi-chevron-down</v-icon>
                </div>
              </v-btn>

            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in newConnectionItems"
                :key="index"
                @click.stop="item.onClick"
              >
                <v-list-item-title class="f-13 d-flex align-center ga-2">
                  <v-icon class="f-14" v-text="item.icon"/>
                  <span>{{ item.title }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            class="f-13"
            color="primary"
            outlined
            @click.stop="refreshBrand"
          >
            {{ $t('facebook_brands_refresh_title') }}
          </v-btn>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          v-show="!loading"é
          :headers="headers"
          :items="facebookBrands"
          mobile-breakpoint="0"
          class="v-datatable-without-y-borders"
          :sort-by="['facebookBrandPlacesCount']"
          :sort-desc="[true]"
          fixed-header
          height="320px"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.name="{ item }">
            <div class="d-flex align-center ga-1">
              <span
                class="fb-14"
              >
                {{ item.name }}
              </span>
              <span
                class="f-12"
              >
                {{ $tc('facebook_brand_places_count', item.locationCount) }}
              </span>
              <div class="d-flex align-center ml-auto ga-1">
                <v-btn
                  icon
                  @click="disconnectFacebookBrand(item.id)"
                >
                  <v-icon
                    small
                  >
                    mdi-logout
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </template>
        </v-data-table>
        <v-skeleton-loader v-show="loading" type="paragraph" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js"></script>
<script>
  import dayjs from 'dayjs'
  import { mapGetters } from 'vuex'
  import _uniq from "lodash/uniq"

  export default {
    name: "FacebookBrandAccountsTable",
    data() {
      return {
        selectedFacebookBrand: [],
        loading: true,
        refreshLoading: false,
        disconnectLoading: false
      }
    },
    computed: {
      ...mapGetters([
        'currentUser',
        'facebookAppId'
      ]),
      headers() {
        return [
          {
            text: this.$t('facebook_brand_name'),
            value: 'name',
            sortable: false
          }
        ]
      },
      newConnectionItems() {
        return [
          {
            title: this.$t('facebook_brands_new_connection_open'),
            onClick: this.openNewConnectionLink,
            icon: 'mdi-open-in-new'
          },
          {
            title: this.$t('facebook_brands_new_connection_copy'),
            onClick: this.copyNewConnectionLink,
            icon: 'mdi-content-copy'
          }
        ]
      },
      connectionCount(){
        return this.facebookBrands.length
      }
    },
    asyncComputed: {
      authorizationUri: {
        async get() {
          let req = this.$basedRequest().select({
            brands: [
              {
                facebook_authorization_uri: {
                  params: {
                    redirect_after_url: location.href
                  }
                }
              }
            ]
          }).where({
            'id': this.currentUser.brandId,
          })

          return (await this.$resolve(req))?.first()?.facebookAuthorizationUri
        }
      },
      facebookBrands: {
        default: [],
        async get() {
          this.loading = true
          let req = this.$basedRequest().select({
            facebook_brands: [
              { 'id': { as: 'id' } },
              { 'COUNT_DISTINCT_lj_facebook_brand_places_id': { as: 'facebookBrandPlacesCount' } },
              { 'COUNT_DISTINCT_lj_unasigned_facebook_brand_places_id': { as: 'unasignedFacebookBrandPlacesCount' } },
              { 'name': { as: 'name' } }
            ]
          }).where({
            'brands_id': this.currentUser.brandId,
          }).group(
            ['id']
          )

          const result = (await this.$resolve(req))?.data || []

          this.loading = false
          return Object.values(result).map(facebookBrand => {
            facebookBrand.locationCount =
              (facebookBrand.facebookBrandPlacesCount || 0) +
              (facebookBrand.unasignedFacebookBrandPlacesCount || 0)

            return facebookBrand
          })
        }
      }
    },
    methods: {
      openNewConnectionLink() {
        window.open(this.authorizationUri, '_blank', 'noreferrer')
      },
      copyNewConnectionLink() {
        const _this = this
        this.$copyText(this.authorizationUri).then(function (e) {
          _this.$store.dispatch('notifySuccess', {
            message: _this.$t(
              'flash_message_facebook_successfully_copy_link',
            ),
            timeout: 5000
          })
        }, function (e) {
          _this.$store.dispatch('notifyError', {
            message: _this.$t(
              'flash_message_facebook_fail_to_copy_link',
            ),
          })
        })
      },
      async disconnectFacebookBrand(facebook_brand_id) {
        if (confirm(this.$t('facebook_disconnect_facebook_brand_confirm_message'))) {
          try {
            this.disconnectLoading = true
            const response = await this.$api.wizville.facebook.destroyFacebookBrand(facebook_brand_id)

            if (response.status === "ok") {
              this.$store.dispatch('notifySuccess', {
                message: this.$t(
                  'flash_message_facebook_successfully_disconnected_facebook_brand',
                ),
                timeout: 5000
              })
            } else {
              this.$store.dispatch('notifyError', { message: response.message })
            }
            this.disconnectLoading = false
          } catch (error) {
            this.disconnectLoading = false
            this.$store.dispatch('notifyError', { message: error.message })
          }
        }
      },
      async refreshBrand() {
        try {
          this.refreshLoading = true
          const response = await this.$api.wizville.facebook.refreshBrand()

          if (response.status === "ok") {
            this.$store.dispatch('notifySuccess', {
              message: this.$t(
                'flash_message_facebook_successfully_refresh_brand',
              ),
              timeout: 5000
            })
          } else {
            this.$store.dispatch('notifyError', { message: response.message })
          }
          this.refreshLoading = false
        } catch (error) {
          this.refreshLoading = false
          this.$store.dispatch('notifyError', { message: error.message })
        }
      }
    }
  }
</script>
