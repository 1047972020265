<template>
  <WLateralSectionLayout class="pl-3 topic-zoom">
    <template #lateral-section>
      <VoterCountGroupedBySourceWidget
        :key="$key('VoterCountGroupedBySourceWidget', dashboardFilterBase64)"
        :cols="12"
      />
    </template>
    <template #main-section>
      <ReviewNumberByPeriodWidget 
        :title="title"
        :basedRequest="basedRequest"
        :cols="12"
      />
    </template>
  </WLateralSectionLayout>
</template>

<script>
  import { mapGetters } from 'vuex'
  import VoterCountGroupedBySourceWidget from '@statistics/SocialPage/SourceZoom/VoterCountGroupedBySourceWidget.vue'
  import ReviewNumberByPeriodWidget from './ReviewNumberByPeriodWidget.vue'

  export default {
    name: "ReviewNumbersPage",
    props: {
    },
    components: {
      VoterCountGroupedBySourceWidget,
      ReviewNumberByPeriodWidget
    },
    watch: {
      campaignId(value) {
        this.$router.push({
          params: { sectionId: (this.currentTopicsByCampaignId[value] || [])[0]?.id }
        })
      }
    },
    computed: {
      ...mapGetters([
        'dashboardFilterRequest',
        'dashboardFilterBase64'
      ]),
      campaign() {
        return this.currentDashboardCampaignWithId(this.campaignId)
      },
      basedRequest() {
        return this.dashboardFilterRequest.select({
          voters: [ "COUNT_DISTINCT_id" ]
        }).order([
          "COUNT_DISTINCT_id", "asc"
        ])
      },
      title() {
        return this.$t(`voter_count_by_source`)
      }
    }
  }
</script>
