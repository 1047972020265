<template>
  <CountGroupedBySourceWidget
    :title="title"
    :basedRequest="basedRequest"
    :linkTo="linkTo"
    :cols="cols"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import CountGroupedBySourceWidget from '../../shared/widgets/CountGroupedBySourceWidget.vue'

export default {
  name: 'VoterCountGroupedBySourceWidget',
  components: {
    CountGroupedBySourceWidget
  },
  props: {
    withLink: { required: false, default: false },
    cols: { required: false, default: 3 }
  },
  computed: {
    ...mapGetters([
      'dashboardFilterRequest'
    ]),
    linkTo() {
      if (this.withLink) {
        return {
          name: 'Statistics',
          params: { sectionId: 'all', sectionType: 'reviewNumbers'}
        }
      } else {
        return ''
      }
    },
    basedRequest() {
      return this.dashboardFilterRequest.select({
        voters: [ "source", "COUNT_DISTINCT_id" ]
      }).group([
        "source"
      ]).order([
        "COUNT_DISTINCT_id", "asc"
      ])
    },
    title() {
      return this.$t(`voter_count_by_source`)
    }
  }
}
</script>
