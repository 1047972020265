<template>
  <div
    class="d-flex flex-column overflow overflow-auto pa-10 ga-10"
    style="height: calc(100% - 39px)"
    v-if="!loading"
  >
    <div
      class="d-flex ga-6 ga-sm-10"
      :class="{
        'flex-column' : $vuetify.breakpoint.xsOnly,
        'two-columns-layout': showTwoColumnsLayout
      }"
    >
      <div
        v-for="data, key, index of customerInformation"
        :key="key"
        class="d-flex flex-column flex-grow-1 flew-shrink-0 flexbox-item ga-2"
      >
        <span
          v-if="key === 'voterProfile'"
          class="c-primary fb-14"
        >
          {{ $store.getters.currentLexicon.translate('customer', 'customer') }}
        </span>
        <span
          class="c-primary fb-14"
          v-else
        >
          {{ $t('experience_panel_title') }}
        </span>

        <div class="d-flex flex-column">
          <div
            class="d-flex flex-column ga-2"
          >
            <div
              v-for="rowData, rowKey of data"
              v-if="rowData.value !== 'anonymized' || rowData.value !== ''"
              :key="rowKey"
              class="d-flex align-center justify-space-between flex-wrap"
            >
              <span class="f-13 c-grey">{{ rowData.title }}</span>
              <RowValue
                :valueType="rowData.type"
                :value="rowData.value === 'u_' ? $t('unknown_language') : rowData.value"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <OtherVoters
      :voter="voter"
    />
  </div>
  <v-skeleton-loader v-else
    type="list-item-three-line"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import _pickBy from "lodash/pickBy"

import RowValue from "./RowValue"
import OtherVoters from "../OtherVoters"

export default{
  name: "VoterCustomerInformationSection",
  computed: {
    ...mapGetters([
    ]),
    showTwoColumnsLayout() {
      return this.$vuetify.breakpoint.smAndUp &&
        this.customerInformation &&
        Object.keys(this.customerInformation).length == 2
    }
  },
  components: {
    RowValue,
    OtherVoters
  },
  data: () => ({
    loading: true
  }),
  props: {
    voter: { required: true }
  },
  asyncComputed: {
    customerInformation: {
      async get() {
        this.loading = true
        const request = this.$basedRequest().where({
          unique_id: this.voter.uniqueId
        }).select({
          voters: [ 'voter_profile', 'voter_experience' ]
        })

        let voterData = (await this.$resolve(request, "customerInformation")).data.voters.pop()

        voterData = _pickBy(voterData, data => Object.keys(data).length)

        this.loading = false

        return voterData
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .flexbox-item
    width: 100%
  .two-columns-layout::before
    align-self: stretch
    content: ''
    border: 0.5px solid var(--mercury)
  .two-columns-layout .flexbox-item:first-child
    order: -1
</style>

