import store from '@platform/store'

import SettingsLayout from "@platform/layouts/SettingsLayout"
import PublicPlatforms from '@platform/pages/settings/PublicPlatforms'
import { legacyOldUrl } from '@shared/helpers/env-helpers'

export default {
  path: "/settings",
  name: "Settings",
  component: SettingsLayout,
  children: [
    {
      name: "settings_my_account",
      path: "my_account",
      component: () => import("@platform/pages/settings/MyAccount")
    },
    {
      name: "local_reputation_monitoring",
      path: "local_reputation_monitoring",
      redirect: { name: "local_reputation_monitoring_overview" },
      component: () => import("@platform/layouts/SettingsLrmLayout"),
      children: [
        {
          name: "local_reputation_monitoring_gmb_overview",
          path: "gmb/overview",
          props: { platform: "gmb" },
          component: () =>
            import(
              "@platform/pages/settings/LocalReputationMonitoring/LocalReputationMonitoringOverview"
            )
        },
        {
          name: "local_reputation_monitoring_gmb_settings",
          path: "gmb/settings",
          props: { platform: "gmb" },
          component: () =>
            import(
              "@platform/pages/settings/LocalReputationMonitoring/LocalReputationMonitoringSettings"
            )
        },
        {
          name: "local_reputation_monitoring_gmb_assignment",
          path: "gmb/assignment",
          props: { platform: "gmb" },
          component: () =>
            import(
              "@platform/pages/settings/LocalReputationMonitoring/LocalReputationMonitoringAssignment"
            )
        }
      ]
    },
    {
      name: "PlacesSettings",
      path: "places",
      component: () => import("@platform/pages/settings/Places")
    },
    {
      name: "VoterInfoRestrictions",
      path: "voter_info_restrictions",
      component: () => import("@platform/pages/settings/VoterInfoRestrictions")
    },
    {
      name: "lexicons",
      path: "lexicons",
      component: () => import("@platform/pages/settings/LexiconsSettings")
    },
    {
      name: "PublicPlatforms",
      path: "public_platforms/:platform",
      component: PublicPlatforms,
      props: (route) => ({
        platform: route.params.platform,
      }),
    },
    {
      name: 'PublicPlatformsFacebook',
      path: 'old_platform/public_platforms/facebook',
      beforeEnter(to, from, next) {
        window.open(`${legacyOldUrl}/settings/facebook?nup=1&alk=${store.getters.currentUser.alk}`, '_self')
      }
    },
    {
      name: 'PublicPlatformsTripAdvisor',
      path: 'old_platform/public_platforms/trip_advisor',
      beforeEnter(to, from, next) {
        window.open(`${legacyOldUrl}/settings/trip_advisor?nup=1&alk=${store.getters.currentUser.alk}`, '_self')
      }
    },
  ]
}
